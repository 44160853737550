import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import Layout from "../components/layout"
import SEO from "../components/seo"

import img1 from "../images/home2.jpg"
import { faClock, faCheck, faThumbsUp, faShippingFast, faLightbulb, faTools, faMedal } from '@fortawesome/free-solid-svg-icons'
import { Link } from 'gatsby'

const ServicesPage  = () => (
    <Layout>
        <SEO title="Nos services d’installation et dépannage dans le Rhône" description="Pour les économies d'énergie, Air water System dans le Rhône vous propose des différentes solutions et services."/>
        <section className="section">
            <div className="container">
                <h1 className="title">Services pour les particuliers et les professionnels dans le Rhône</h1>
                <h2 className="subtitle">Air Water System, pour vos travaux d’installation, de dépannage et d’entretien dans le Rhône</h2>
                <p className="content">
                    <div className="columns">
                        <div className="column is-1 has-text-success has-text-centered">
                            <FontAwesomeIcon icon={faLightbulb} size="3x"/>
                        </div>
                        <div className="column is-11"> 
                            Pour les économies d'énergie, Air water System à Mions vous propose des différentes solutions.
                            <br></br>
                            Que ce soit pour vos clients ou pour vos employés, le confort fait partie intégrante d’une bonne condition de travail.
                        </div>
                    </div>
                    <div className="columns">
                        <div className="column is-1 has-text-info has-text-centered">
                            <FontAwesomeIcon icon={faMedal} size="3x"/>
                        </div>
                        <div className="column is-11"> 
                            Fortement impliquée dans la qualité de ses services, l’entreprise Air Water System propose une gamme de produits sélectionnés pour leur notoriété et leurs performances, afin de correspondre précisément aux besoins d’une entreprise.
                        </div>
                    </div>
                    <div className="columns">
                        <div className="column is-1 has-text-danger has-text-centered">
                            <FontAwesomeIcon icon={faTools} size="3x"/>
                        </div>
                        <div className="column is-11">
                            Et pour que ce confort soit optimal à long terme, nous effectuons avec soins l’entretien des installations de <Link to="/climatisation-lyon">climatisation</Link> et de chauffage, mais aussi votre <Link to="/plomberie">plomberie</Link> et vos adoucisseurs d'eau. 
                        </div>
                    </div>
                </p>
            </div>
        </section>
        <section className="section">
            <div className="container">
                <div className="columns">
                    <div className="column">
                        <h3 className="title is-4">
                            Pour les professionnels
                        </h3>
                        <p className="content" style={{height:50}}>
                            Nos climaticiens chauffagistes mettent à la disposition des professionnels diverses prestations, à savoir :
                        </p>
                        <ul className="list">
                            <li className="list-item">Climatisation et chauffage</li>
                            <li className="list-item">Climatisation murale</li>
                            <li className="list-item">Climatisation plafonnier</li>
                            <li className="list-item">Climatisation encastrable</li>
                            <li className="list-item">VMC</li>
                        </ul>
                    </div>
                    <div className="column">
                        <h3 className="title is-4">
                            Pour les particuliers
                        </h3>
                        <p className="content" style={{height:50}}>
                            Nos plombiers expérimentés vous proposent plusieurs services, tels que :
                        </p>
                        <ul className="list">
                            <li className="list-item">Chauffage et climatisation</li>
                            <li className="list-item">Pompe à chaleur/inverter</li>
                            <li className="list-item">Chauffage solaire</li>
                            <li className="list-item">Plancher chauffant</li>
                            <li className="list-item">Contrat d’entretien</li>
                        </ul>
                    </div>
                </div>
            </div>
        </section>
        <section className="section">
            <div className="container">
                <h2 className="title">Nos garanties</h2>
                <div className="content">
                    <p>Pour vous apporter le meilleur service, notre entreprise Air Water System vous garantit :</p>
                    <div class="level">
                        <div class="level-item has-text-centered">
                            <div>
                            <p class="heading">Le respect des délais</p>
                            <p class="title">
                                <span class="icon is-large"><FontAwesomeIcon icon={faClock} size="lg"/></span>
                            </p>
                            </div>
                        </div>
                        <div class="level-item has-text-centered">
                            <div>
                            <p class="heading">Un service de qualité</p>
                            <p class="title">
                                <span class="icon is-large"><FontAwesomeIcon icon={faCheck} size="lg"/></span>
                            </p>
                            </div>
                        </div>
                        <div class="level-item has-text-centered">
                            <div>
                            <p class="heading">Un travail bien soigné</p>
                            <p class="title">
                                <span class="icon is-large"><FontAwesomeIcon icon={faThumbsUp} size="lg"/></span>
                            </p>
                            </div>
                        </div>
                        <div class="level-item has-text-centered">
                            <div>
                            <p class="heading">Une intervention rapide</p>
                            <p class="title">
                                <span class="icon is-large"><FontAwesomeIcon icon={faShippingFast} size="lg"/></span>
                            </p>
                            </div>
                        </div>
                    </div>
                    <em style={{opacity:"0.5"}} className="has-text-centered">Pour les particuliers, la TVA est à 10%</em>
                </div>
            </div>
        </section>
        <figure className="image">
            <img alt="© Air Water System" src={img1}></img>
        </figure>
        <section className="section">
            <div className="container">
                <p className="content">
                    Pour plus de renseignements concernant l'installation ou la réparation de votre chauffage, votre plomberie ou votre climatisation,  n’hésitez pas à contacter notre entreprise Air Water System à Mions.
                </p>
            </div>
        </section>    
    </Layout>
)

export default ServicesPage;
